html {
  --accent-color: #ea0961;
  box-sizing: border-box;
  font-family: monospace;
  font-size: 18px;
  line-height: 1.5;
}

html *, html :before, html :after {
  box-sizing: inherit;
}

body {
  color: #161616;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

article, aside, figcaption, figure, footer, header, main, nav, section {
  display: block;
}

h1 {
  margin: 0 0 1.6rem;
  font-size: 2rem;
}

h2 {
  margin: 1.6rem 0 1.3rem;
  font-size: 1.6rem;
}

h3 {
  margin: 1.6rem 0 1.1rem;
  font-size: 1.3rem;
}

h4 {
  margin: 1.3rem 0 1.1rem;
  font-size: 1.1rem;
}

h5, h6 {
  margin: 1rem 0;
  font-size: 1rem;
}

p {
  margin: 1rem 0;
}

ul, ol {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

li {
  margin-top: 0;
  margin-bottom: .1rem;
}

li > :first-child:where(h2, h3, h4, h5, h6, p, ul, ol, dl) {
  margin-top: 0;
}

li > :last-child:where(h2, h3, h4, h5, h6, p, ul, ol, dl), li:last-child {
  margin-bottom: 0;
}

dl {
  margin: 1.2rem 0;
}

dl > div {
  margin-bottom: 1rem;
}

dl > div:last-child {
  margin-bottom: 0;
}

dt {
  margin: 0 0 .5rem;
  font-weight: bold;
}

dd {
  margin: 0 0 0 2.2rem;
}

dd > :first-child:where(h2, h3, h4, h5, h6, p, ul, ol, dl) {
  margin-top: 0;
}

dd > :last-child:where(h2, h3, h4, h5, h6, p, ul, ol, dl) {
  margin-bottom: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

th {
  padding: .3rem .5rem;
  font-size: 1rem;
  font-weight: bold;
}

th > :first-child:where(h2, h3, h4, h5, h6, p, ul, ol, dl) {
  margin-top: 0;
}

th > :last-child:where(h2, h3, h4, h5, h6, p, ul, ol, dl) {
  margin-bottom: 0;
}

td {
  padding: .3rem .5rem;
  font-size: 1rem;
}

td > :first-child:where(h2, h3, h4, h5, h6, p, ul, ol, dl) {
  margin-top: 0;
}

td > :last-child:where(h2, h3, h4, h5, h6, p, ul, ol, dl) {
  margin-bottom: 0;
}

a {
  color: inherit;
  cursor: pointer;
}

mark {
  color: #000;
  background-color: #fffd76;
}

img {
  border: none;
  max-width: 100%;
  margin: 0;
  display: block;
}

svg {
  max-width: 100%;
  margin: 0;
  display: block;
}

figure {
  margin: 1rem 0;
}

form {
  margin: 1.4rem 0;
  padding: 0;
}

input, button, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: .4rem .7rem;
}

button {
  padding: .4rem .9rem;
}

select {
  padding: .5rem .7rem;
}

button {
  cursor: pointer;
}

:focus-visible {
  outline: 2px solid var(--accent-color);
  outline-offset: 2px;
}

.u-no-margin-bottom {
  margin-bottom: 0;
}

.u-no-margin-top {
  margin-top: 0;
}

.u-no-margin-y {
  margin-top: 0;
  margin-bottom: 0;
}

.u-no-inner-margin-bottom > :last-child {
  margin-bottom: 0;
}

.u-no-inner-margin-top > :first-child, .u-no-inner-margin-y > :first-child {
  margin-top: 0;
}

.u-no-inner-margin-y > :last-child {
  margin-bottom: 0;
}

ul.u-no-marker, ol.u-no-marker {
  margin-left: 0;
  padding: 0;
  list-style-type: none;
}

ul.u-no-marker > li, ol.u-no-marker > li {
  padding: 0;
}

.u-breathing-room > * {
  margin-top: .3rem;
  margin-bottom: .3rem;
}

.u-breathing-room > :last-child {
  margin-bottom: 0;
}

.u-breathing-room.is-large > * {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.u-breathing-room.is-large > :last-child {
  margin-bottom: 0;
}

.u-hidden {
  display: none;
}

.ui-breakdown-bar {
  gap: 2px;
  display: flex;
}

.ui-breakdown-bar__item {
  border-radius: 2px;
  min-width: 2px;
  height: 24px;
}

.ui-content-wrapper {
  padding: 1.6rem 2rem .6rem;
}

.ui-dots {
  justify-content: center;
  gap: 5px;
  display: flex;
}

.ui-dots__dot {
  border-radius: 10px;
  width: 10px;
  height: 10px;
}

.ui-emoji {
  font-family: arial, verdana, sans-serif;
}

.ui-folder__header {
  z-index: 2;
  background-color: #fff;
  border-bottom: 2px solid #333;
  margin: 2.4rem 0 0;
  font-weight: 400;
  display: flex;
  position: sticky;
  top: 0;
}

.ui-folder__tab {
  color: #fff;
  background-color: #333;
  border-top-right-radius: 3px;
  flex: none;
  padding: .5rem 1.8rem .4rem 1.5rem;
}

.ui-folder__main {
  border: 2px solid #333;
  padding: 1.5rem 1.6rem 1.6rem 1.5rem;
}

.ui-folder__main > :first-child {
  margin-top: 0;
}

.ui-folder__main > :last-child {
  margin-bottom: 0;
}

.ui-readable-width {
  max-width: 1000px;
}

.ui-revert {
  color: #fff;
  background-color: #ff294e;
}

.ui-row {
  align-items: center;
  gap: 12px;
  display: flex;
}

.ui-row__item {
  flex: 0 auto;
}

.ui-rule {
  border: none;
  border-top: 1px solid #999;
  margin: 1.9rem 0 1.8rem;
  padding: 0;
}

.ui-rule.is-soft {
  border-top: 1px dashed #aaa;
}

.ui-snippet {
  color: #000;
  tab-size: 4;
  white-space: pre-wrap;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 1.8rem 0;
  padding: .9rem 1rem .8rem 1.1rem;
  font-size: 90%;
  line-height: 1.6;
  display: block;
}

.ui-snippet > code {
  display: block;
}

.ui-tag {
  border-radius: .2rem;
  padding: .2rem .5rem;
  display: block;
}

.ui-tag.is-inline {
  display: inline-block;
}

.ui-tag.is-stroked {
  border: 1px solid #ccc;
}

.ui-tag.is-value {
  border: 1px solid #f55dff;
}

.ui-tag.is-role {
  border: 1px solid #ccc;
}

.ui-tag-list {
  flex-wrap: wrap;
  gap: .8rem;
  display: flex;
}

.ui-variant-1 {
  color: #f1f2ff;
  background-color: #5661ff;
}

.ui-variant-2 {
  color: #f7fff6;
  background-color: #65b752;
}

.ui-variant-3 {
  color: #0c0c0c;
  background-color: #f8bf4d;
}

.ui-variant-4 {
  color: #fdfdfd;
  background-color: #ff4d7f;
}

.ui-variant-5 {
  color: #feffff;
  background-color: #7f34d6;
}

.ui-variant-6 {
  color: #272727;
  background-color: #ffeb0d;
}

.ui-variant-7 {
  color: #fff;
  background-color: #3bd4d9;
}

.ui-variant-8 {
  color: #11323b;
  background-color: #6eff89;
}

.ui-variant-9, .ui-variant-10, .ui-variant-11, .ui-variant-12, .ui-variant-13, .ui-variant-14, .ui-variant-15, .ui-variant-0 {
  color: #fff;
  background-color: #ff5b53;
}

.ui-button {
  background-color: #efefef;
  border: 1px solid #111;
  border-radius: 2px;
  padding: .5rem 1.3rem .4rem;
  display: block;
}

.ui-button.is-plain {
  background-color: #0000;
  border-color: #0000;
}

.ui-button.is-submit {
  color: #fff;
  background-color: #333;
  border-color: #111;
}

.ui-button.is-destructive {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.ui-button.is-cancel {
  background-color: #0000;
  border-color: #d0d0d0;
}

.ui-button.is-cancel:hover {
  border: 1px solid #111;
}

.ui-button.is-text {
  background-color: #0000;
  border: none;
  border-radius: 0;
  padding: 0;
  text-decoration: underline;
}

.ui-form-buttons {
  border-top: 1px solid #ccc;
  margin-top: 1.4rem;
  padding-top: 1.4rem;
}

.ui-input {
  border: 1px solid #111;
  border-radius: 2px;
  width: 400px;
  padding: .5rem .7rem .4rem;
  display: block;
}

.ui-input.is-large {
  width: 600px;
}

.ui-radio {
  appearance: none;
  border: 1px solid #111;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  margin: 0;
  padding: 0;
  display: flex;
}

.ui-radio:checked:after {
  background-color: var(--accent-color);
  content: "";
  border-radius: 8px;
  width: 8px;
  height: 8px;
  margin: auto;
}

.ui-select {
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg width=\"13\" height=\"7\" viewBox=\"0 0 13 7\" xmlns=\"http://www.w3.org/2000/svg\"><polygon stroke=\"none\" fill=\"currentColor\" fill-rule=\"evenodd\" points=\"0 0 6.5 7 13 0\"></polygon></svg>");
  background-position: right 10px top 52%;
  background-repeat: no-repeat;
  border: 1px solid #111;
  border-radius: 2px;
  padding: .5rem 2rem .4rem .7rem;
}

.ui-textarea {
  border: 1px solid #111;
  border-radius: 2px;
  width: 600px;
  height: 6rem;
  padding: .5rem .7rem .4rem;
  display: block;
}
/*# sourceMappingURL=main.4f696ac6.css.map */
